
  import SignaturePad from "@/components/da-signer/SignaturePad.vue";

  export default {
    name: "MobileSigner",
    options: {
      auth: false,
    },
    components: {
      SignaturePad,
    },
    data() {
      return {
        sent: false,
      };
    },
    computed: {
      token() {
        return this.$router.history.current.query.token;
      },
    },
    methods: {
      async sendSignature() {
        if (this.$refs.signaturePad.isEmpty()) {
          this.$flashMessage.error({
            title: `${this.$t(`messages.error`)}`,
            message: `${this.$t(`components.da_signer.signature_pad.empty_signature`)}`
          });
          return;
        }

        if (this.$refs.signaturePad.isTooSmall()) {
          this.$flashMessage.error({
            title: `${this.$t(`messages.error`)}`,
            message: `${this.$t(`components.da_signer.signature_pad.too_small_signature`)}`
          });
          return;
        }

        const dataPoints = this.$refs.signaturePad.getData();
        const data = {
          token: this.token,
          data: dataPoints,
          dimensions: this.$refs.signaturePad.getDimensions(),
        };
        await this.$store.dispatch(`daSigner/sendSignature`, { data });
        this.sent = true;
      },
    },
  };
